import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="flex flex-col w-full container items-center">
        <h1 className="text-2xl">
          Looks like you took a wrong turn. Go back to <a href="/" className="underline text-action">Home</a>
        </h1>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
